import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import TaskEditMask from "./TaskEditMask";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchUserAccessibleCampuses,
  fetchCampusAssignedUsers,
  postNewTasks,
} from "../actions/api";
import { PAPER_ELEVATION } from "../../constants";

function CreateTask() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [task, setTask] = useState(() => ({
    title: "",
    due_date: "",
    priority: { text: "", api: "", key: "" },
    status: { text: "", api: "", key: "" },
    task_group: { text: "", api: "", key: "" },
    assigned_to: [], // Array of user objects
    campus: { id: "" }, // Selected campus
  }));

  const [campuses, setCampuses] = useState([]);
  const [campusUsers, setCampusUsers] = useState([]);
  const [allowAssignedTo, setAllowAssignedTo] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserAccessibleCampuses();
        setCampuses(data);
      } catch (error) {
        console.error("Error fetching campuses:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const data = await fetchCampusAssignedUsers(id);
        setCampusUsers(data);
        setAllowAssignedTo(true);
      } catch (error) {
        console.error("Error fetching campus users:", error);
        setAllowAssignedTo(false);
      }
    };
    if (task.campus.id) {
      fetchData(task.campus.id);
    }
  }, [task.campus]);

  const handleCreateTask = async (e) => {
    e.preventDefault();

    // Filter out invalid user objects from assigned_to
    const sanitizedAssignedTo = task.assigned_to.filter(
      (user) => user && user.id
    );

    if (sanitizedAssignedTo.length !== task.assigned_to.length) {
      console.warn(
        "Invalid user objects found and removed from assigned_to:",
        task.assigned_to
      );
    }

    const sanitizedData = {
      title: task.title,
      due_date: task.due_date,
      priority: task.priority.api,
      status: task.status.api,
      task_group: task.task_group.api,
      assigned_to: sanitizedAssignedTo.map((user) => user.id), // Map to valid user IDs
      campus: task.campus.id,
    };

    console.log("Sanitized Data Sent to API:", sanitizedData);

    try {
      const result = await postNewTasks(sanitizedData);
      if (result) {
        navigate("/tasks");
      } else {
        setUpdateFailed(true);
      }
    } catch (error) {
      console.error(
        "Error creating task:",
        error.response?.data || error.message
      );
      setUpdateFailed(true);
    }
  };

  return (
    <Workspace
      isLoading={false}
      title={t("CREATE TASK")}
      titleIcon={<AddIcon color="primary" sx={{ fontSize: 40 }} />}
    >
      <Paper
        elevation={PAPER_ELEVATION}
        sx={{
          maxWidth: "600px",
          p: 4,
          background: theme.palette.background.default,
          display: "flex",
        }}
      >
        <form onSubmit={handleCreateTask}>
          <TaskEditMask
            val={task}
            setVal={setTask}
            campuses={campuses}
            campusUsers={campusUsers}
            allowAssign={allowAssignedTo}
            buttonActionText={t("ADD")}
          />
        </form>
      </Paper>
      {updateFailed && (
        <Box sx={{ my: 2, color: "error.main" }}>
          {t("CREATION_FAILED_CHECK_ERRORS")}
        </Box>
      )}
    </Workspace>
  );
}

export default CreateTask;
