import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Paper, TextField, Autocomplete, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import TaskEditMask from "./TaskEditMask";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { fetchTaskById, patchTaskMetaData } from "../actions/api";

function EditTask() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [task, setTask] = useState({
    title: "",
    due_date: "",
    priority: { text: "", api: "", key: "" },
    status: { text: "", api: "", key: "" },
    task_group: { text: "", api: "", key: "" },
    assigned_to: [], // Users currently assigned
    campus_assigned_users: [], // All users available for the campus
    campus: { id: "", name: "" },
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch task details on component mount
  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const data = await fetchTaskById(id);
        setTask({
          title: data.title,
          due_date: data.due_date,
          priority: {
            text: data.priority,
            api: data.priority,
            key: data.priority.toLowerCase(),
          },
          status: {
            text: data.status,
            api: data.status,
            key: data.status.toLowerCase(),
          },
          task_group: {
            text: data.task_group,
            api: data.task_group,
            key: data.task_group.toLowerCase(),
          },
          assigned_to: data.assigned_to_details || [], // Populate assigned users
          campus_assigned_users: data.campus_assigned_users || [], // Populate campus users
          campus: { id: data.campus, name: data.campus_name },
        });
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [id]);

  // Handle task updates
  const handleUpdateTask = async (e) => {
    e.preventDefault();
    const sanitizedData = {
      title: task.title,
      due_date: task.due_date,
      priority: task.priority.api,
      status: task.status.api,
      task_group: task.task_group.api,
      assigned_to: task.assigned_to.map((user) => user.id), // Convert assigned users to IDs
      campus: task.campus.id,
    };

    try {
      const success = await patchTaskMetaData(id, sanitizedData);
      if (success) {
        setUpdateSuccess(true);
        setTimeout(() => navigate("/tasks"), 2000); // Redirect after success
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  if (loading) {
    return <Workspace isLoading={true} title={t("Edit Task")} />;
  }

  return (
    <Workspace
      isLoading={false}
      title={t("Edit Task")}
      titleIcon={<ModeEditIcon color="primary" sx={{ fontSize: 40 }} />}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: "600px",
          p: 3,
          background: theme.palette.background.default,
        }}
      >
        {updateSuccess && (
          <Box sx={{ color: "success.main", mb: 2 }}>
            {t("Task updated successfully!")}
          </Box>
        )}
        <form onSubmit={handleUpdateTask}>
          <TaskEditMask
            val={task}
            setVal={setTask}
            campuses={[task.campus]} // Prefill campus details
            campusUsers={task.campus_assigned_users} // Prefill available users
            buttonActionText={t("Update")}
            allowAssign={true}
          />

          {/* Assign Users dropdown */}
          <Autocomplete
            multiple
            options={task.campus_assigned_users} // Available users for assignment
            getOptionLabel={(option) => option.email} // Show email addresses in dropdown
            value={task.assigned_to} // Prefill with currently assigned users
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, newValue) => {
              setTask((prev) => ({ ...prev, assigned_to: newValue }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("Assign Users")}
              />
            )}
            sx={{ mt: 2 }}
          />
        </form>
      </Paper>
    </Workspace>
  );
}

export default EditTask;
