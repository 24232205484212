import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Popper, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useDrawerWidth } from "../../size";

/**
 * Generic autocomplete field for large and small screens
 * @param {Array} dataList - List with elements (objects or strings, both possible)
 * @param {Function} changeHandler - Function that handles onChange callback, returns selected value
 * @param {String/Array} value - Actual selection, presented in field, with multiple selection, this must be an Array
 * @param {Object} style - Styling props, handed over to sx field of Autocomplete
 * @param {Object} popperStyle - Styling props for popper, set 'placement' and 'width'
 * @param {String} size - String for Autocomplete property 'size', 'small' or 'medium'
 * @param {String} label - Lable for text field (user help)
 * @param {String} value - Actual selection, presented in field
 * @param {Boolean} multiple - Multiple selection
 * @returns {JSX.Element} The rendered Autocomplete component
 *  */
const GenericAutocomplete = ({
  dataList = [],
  changeHandler,
  value,
  style,
  size,
  label,
  popperStyle = {},
  multiple = false,
}) => {
  const theme = useTheme();
  const [all, setAll] = useState([]);
  const drawerWidth = useDrawerWidth();

  useEffect(() => {
    // console.log("Incoming dataList:", dataList);

    if (Array.isArray(dataList)) {
      const isArrayOfStrings = dataList.every(
        (item) => typeof item === "string"
      );
      let mod = [];

      if (isArrayOfStrings) {
        mod = dataList.map((val) => ({ label: val }));
      } else {
        mod = dataList.map((item, index) => {
          const label =
            item?.name || item?.label || `Unnamed item ${index + 1}`;
          return { ...item, label };
        });
      }
      setAll(mod);
    } else {
      console.warn("Expected dataList to be an array but received:", dataList);
    }
  }, [dataList]);

  // Increase the popper width, where necessary
  const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      width: popperStyle.width,
    },
    "& .MuiAutocomplete-popper": {
      width: popperStyle.width,
    },
    "& .MuiAutocomplete-paper": {
      width: popperStyle.width,
    },
  });
  // Placement options
  //   "bottom-start" (default)
  // "bottom"
  // "bottom-end"
  // "top-start"
  // "top"
  // "top-end"
  // "left-start"
  // "left"
  // "left-end"
  // "right-start"
  // "right"
  // "right-end"
  const PopperWithPlacement = (props) => (
    <StyledPopper {...props} placement={popperStyle.placement} />
  );

  return (
    <Autocomplete
      disablePortal
      multiple={multiple}
      getOptionLabel={(option) => option.label || "Unnamed"}
      id={all.length === 0 ? "noId" : all[0].label}
      size={size}
      options={all}
      PopperComponent={PopperWithPlacement}
      onChange={(event, newValue) => {
        console.log("Changed selection to:", newValue);
        changeHandler(newValue);
      }}
      value={value}
      sx={{
        marginBottom: "1rem",
        ...style,
      }}
      renderInput={(params) => (
        <TextField
          className="selectorAuto"
          {...params}
          label={label}
          error={!value || (Array.isArray(value) && value.length === 0)}
          variant="outlined"
        />
      )}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.id || index}
            label={option.label || "Unnamed"}
            className={drawerWidth === 0 ? "mobile" : ""}
            {...getTagProps({ index })}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.secondary.contrastText,
              "& .MuiChip-deleteIcon": {
                color: theme.palette.secondary.contrastText,
              },
            }}
          />
        ))
      }
    />
  );
};

export default GenericAutocomplete;
