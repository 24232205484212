import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { useTranslation } from "react-i18next";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import { useDrawerWidth } from "../../size";
import { Paper, useMediaQuery } from "@mui/material";
import { PAPER_ELEVATION, BORDER_RADIUS } from "../../constants";
import {
  fetchCustomerAccessibleCampus,
  fetchChartTargets,
  fetchChartViews,
} from "../actions/api";
import { getElementHeightById } from "./chartFuncs";

const defaultChartHeaderObject = {
  // customer
  customerID: null,

  // campus
  campusId: null,
  campusName: null,
  campusHaystackId: null,
  campusTimeZone: null,

  // Chart Group
  selectedChartGroup: null, // Store selected option object
  selectedChartGroupData: [],

  // Sub Group
  selectedChartSubGroup: null, // Store selected option object
  selectedChartSubGroupData: [],

  selectedSubGroupTarget: null,
  //selectedChartDetails: {},
  selectedChartViewDef: null,
  selectedSubGroupCharts: [],

  // targetIds: [], // target ids
  targetData: [],
  chartData: [],

  reset: false,
};

// Hack for demos, replace '-demo' in haystack id with empty string
const hackDemo = (str) => {
  if (str && str.endsWith("-demo")) {
    return str.replace(/-demo$/, "");
  }
  return str;
};

/**
 * Header element with selectors for campus, main, and subtopic
 * @param {Function} objectHandler - Function that collects targets from API call. Returns the object 'targetData' with an array empty or with data
 * @returns {JSX.Element} The rendered Autocomplete component
 */
const ChartHeader = ({ objectHandler }) => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth(); // if 0 => mobile
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isLargeTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );
  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );
  const isVeryLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );
  const [height, setHeight] = useState(0);
  const [allCampuses, setAllCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(null);

  const [chartHeaderObject, setChartHeaderObject] = useState(() => {
    const saved = localStorage.getItem("chartHeaderObject");
    const parsed = saved ? JSON.parse(saved) : null;
    if (parsed) {
      // Apply hackDemo to campusHaystackId if necessary
      return {
        ...defaultChartHeaderObject,
        ...parsed,
        campusHaystackId: hackDemo(parsed.campusHaystackId),
      };
    } else {
      return defaultChartHeaderObject;
    }
  });

  // Fetch campuses from all customers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerDataResponse = await fetchCustomerAccessibleCampus();
        console.log("Fetched customer data:", customerDataResponse);

        if (customerDataResponse && customerDataResponse.results.length > 0) {
          // Collect campuses from all customers
          const campuses = [];
          customerDataResponse.results.forEach((customerData) => {
            const customerID = customerData.customer_id;
            const customerCampuses = customerData.campuses || [];
            // Add customerID to each campus
            customerCampuses.forEach((campus) => {
              campus.customerID = customerID;
            });
            campuses.push(...customerCampuses);
          });

          console.log("Collected campuses:", campuses);

          if (campuses.length > 0) {
            const processedCampuses = campuses.map((campus) => ({
              ...campus,
              label: campus.name || "Unnamed Campus",
              campus_haystack_model_id: hackDemo(
                campus.campus_haystack_model_id
              ),
              sites: campus.sites.map((site) => ({
                ...site,
                campus_id: campus.id,
              })),
            }));
            setAllCampuses(processedCampuses);
          } else {
            console.error(t("No campuses available for this user."));
          }
        } else {
          console.error(t("No customer data available."));
        }
      } catch (error) {
        console.error(t("Error fetching customer data."), error);
      }
    };
    fetchData();
  }, [t]);

  // Fetch chart views when campus changes
  const fetchChartViewData = useCallback(async () => {
    if (chartHeaderObject.customerID && chartHeaderObject.campusId) {
      try {
        console.log("Customer ID:", chartHeaderObject.customerID);
        console.log("Campus ID:", chartHeaderObject.campusId);

        // Get the language parameter from useTranslation
        const language = t.language || "de"; // Default to 'de' if language is undefined

        console.log("Language for chart views:", language);

        const chartData = await fetchChartViews(
          chartHeaderObject.customerID,
          chartHeaderObject.campusId,
          language // Include language parameter
        );

        // Check if chartData is populated
        console.log("Fetched chartData:", chartData);

        if (chartData && chartData.length > 0) {
          const uniqueChartGroups = [
            ...new Set(chartData.map((item) => item.chartGroup)),
          ];

          const organizedChartData = uniqueChartGroups.map((name) => ({
            chartGroupName: name,
            subGroups: chartData
              .filter((item) => item.chartGroup === name)
              .map((subGroupItem) => ({
                ...subGroupItem, // Include all properties
              })),
          }));

          console.log("Organized Chart Data:", organizedChartData);

          setChartHeaderObject((prevChartObject) => ({
            ...prevChartObject,
            chartData: organizedChartData,
          }));
        } else {
          console.warn("No chart data received for this campus.");
          setChartHeaderObject((prevChartObject) => ({
            ...prevChartObject,
            chartData: [],
          }));
        }
      } catch (error) {
        console.error("Error fetching chart views:", error);
      }
    } else {
      console.warn("Customer ID or Campus ID is missing.");
    }
  }, [chartHeaderObject.customerID, chartHeaderObject.campusId]);

  useEffect(() => {
    fetchChartViewData();
  }, [fetchChartViewData]);

  // Fetch chart targets when necessary data is available
  const fetchChartTargetsData = useCallback(async () => {
    try {
      const {
        customerID,
        campusId,
        selectedSubGroupTarget,
        campusHaystackId,
        selectedChartViewDef,
      } = chartHeaderObject;

      if (
        customerID &&
        campusId &&
        selectedSubGroupTarget &&
        campusHaystackId &&
        selectedChartViewDef
      ) {
        // Apply hackDemo to campusHaystackId
        const adjustedCampusHaystackId = hackDemo(campusHaystackId);

        const oxoia_command = `${selectedSubGroupTarget},${adjustedCampusHaystackId},${selectedChartViewDef}`;

        console.log("Fetching chart targets with command:", oxoia_command);

        const targets = await fetchChartTargets(
          customerID,
          campusId,
          oxoia_command
        );

        console.log("Fetched chart targets:", targets);

        setChartHeaderObject((prevChartObject) => ({
          ...prevChartObject,
          targetData: targets,
        }));
      } else {
        console.warn("Missing data to fetch chart targets:", {
          customerID,
          campusId,
          selectedSubGroupTarget,
          campusHaystackId,
          selectedChartViewDef,
        });
      }
    } catch (error) {
      console.error("Error fetching chart targets:", error);
    }
  }, [
    chartHeaderObject.customerID,
    chartHeaderObject.campusId,
    chartHeaderObject.selectedSubGroupTarget,
    chartHeaderObject.campusHaystackId,
    chartHeaderObject.selectedChartViewDef,
  ]);

  useEffect(() => {
    fetchChartTargetsData();
  }, [
    chartHeaderObject.customerID,
    chartHeaderObject.campusId,
    chartHeaderObject.selectedSubGroupTarget,
    chartHeaderObject.campusHaystackId,
    chartHeaderObject.selectedChartViewDef,
    fetchChartTargetsData,
  ]);

  // Update parent component with new data
  useEffect(() => {
    objectHandler({
      chartHeaderObject: chartHeaderObject, // For links
      targetData: chartHeaderObject.targetData,
      chartViewData: {
        customerID: chartHeaderObject.customerID,
        campusId: chartHeaderObject.campusId,
        campusName: chartHeaderObject.campusName,
        campusHaystackId: chartHeaderObject.campusHaystackId,
        campusTimeZone: chartHeaderObject.campusTimeZone,
        selectedChartViewDef: chartHeaderObject.selectedChartViewDef,
        selectedSubGroupTarget: chartHeaderObject.selectedSubGroupTarget,
        charts: chartHeaderObject.selectedSubGroupCharts, // Include charts
      },
    });
  }, [chartHeaderObject, objectHandler]);

  // Size observer to dynamically set height
  useEffect(() => {
    const observedElementId = "range-select-paper";
    const observedElement = document.getElementById(observedElementId);

    const updateHeight = () => {
      const newHeight = getElementHeightById(observedElementId);
      if (newHeight !== null) {
        setHeight(newHeight);
      }
    };

    updateHeight();
    const resizeObserver = new ResizeObserver(updateHeight);
    if (observedElement) {
      resizeObserver.observe(observedElement);
    }

    return () => {
      if (observedElement) {
        resizeObserver.unobserve(observedElement);
      }
    };
  }, []);

  // Campus selection handler
  const handleSelectCampus = (val) => {
    if (val !== null && val !== undefined) {
      setChartHeaderObject((prev) => ({
        ...prev,
        customerID: val.customerID, // Update customerID based on selected campus
        campusId: val.id,
        campusName: val.name,
        campusHaystackId: hackDemo(val.campus_haystack_model_id),
        campusTimeZone: val.timezone || "Europe/Zurich",
        selectedChartGroup: null,
        selectedChartGroupData: [],
        selectedChartSubGroup: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedChartViewDef: null,
        selectedSubGroupCharts: [], // Reset charts
        reset: true,
        targetData: [],
      }));
      objectHandler({
        targetData: [],
        chartViewData: {},
        chartHeaderObject: null,
      });
      setSelectedCampus(val);
    }
  };

  // Chart group selection handler
  const handleSelectChartGroup = (group) => {
    if (group !== null && group !== undefined) {
      const selectedGroup = chartHeaderObject.chartData.find(
        (f) => f.chartGroupName === group.label
      );

      console.log("Selected Chart Group Data:", selectedGroup);

      setChartHeaderObject((prev) => ({
        ...prev,
        selectedChartGroup: group,
        selectedChartGroupData: selectedGroup ? selectedGroup.subGroups : [],
        selectedChartSubGroup: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedChartViewDef: null,
        selectedSubGroupCharts: [], // Reset charts
        reset: true,
        targetData: [],
      }));
      objectHandler({
        targetData: [],
        chartViewData: {},
        chartHeaderObject: null,
      });
    }
  };

  // Chart subgroup selection handler
  const handleSelectChartSubGroup = (subgroup) => {
    if (subgroup !== null && subgroup !== undefined) {
      const selectedDataElement = chartHeaderObject.selectedChartGroupData.find(
        (f) => f.chartSubGroup === subgroup.label
      );

      console.log("Selected Chart SubGroup Data:", selectedDataElement);

      setChartHeaderObject((prev) => ({
        ...prev,
        selectedChartSubGroup: subgroup,
        selectedChartSubGroupData: selectedDataElement || {},
        selectedSubGroupTarget: selectedDataElement?.target || null,
        selectedChartViewDef: selectedDataElement?.chartViewDef || null,
        selectedSubGroupCharts: selectedDataElement?.charts || [], // Include charts
        reset: true,
      }));
    }
  };
  const width = 400;
  const padding = 30;
  return (
    <Paper
      sx={{
        width: isVeryLargeScreen ? `${width}px` : "100%",
        padding: "1rem",
        height: isVeryLargeScreen ? `${height}px` : null,
        borderRadius: `${BORDER_RADIUS}px`,
        display: "flex",
        flexDirection: isMobile
          ? "column"
          : isTablet
          ? "column"
          : isLargeTablet
          ? "row"
          : isLargeScreen
          ? "row"
          : "column",
      }}
      elevation={PAPER_ELEVATION}
    >
      {/* Show only first selected item */}
      <GenericAutocomplete
        multiple={false}
        dataList={allCampuses}
        changeHandler={handleSelectCampus}
        value={selectedCampus}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        style={{
          // paddingBottom: isMobile ? '1rem' : isTablet? '1rem' : isLargeTablet ?  0 : isLargeScreen ? 0 : '1rem',
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingRight: isMobile
            ? 0
            : isTablet
            ? 0
            : isLargeTablet
            ? "1rem"
            : isLargeScreen
            ? "1rem"
            : 0,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
        label={t("Campus")}
      />
      <GenericAutocomplete
        dataList={
          chartHeaderObject.chartData
            ? chartHeaderObject.chartData.map((item) => ({
                label: item.chartGroupName,
              }))
            : []
        }
        changeHandler={handleSelectChartGroup}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        value={chartHeaderObject.selectedChartGroup}
        label={t("CHARTCAT")}
        popperStyle={
          {
            // width: isMobile ? "300px" : "auto",
          }
        }
        style={{
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingRight: isMobile
            ? 0
            : isTablet
            ? 0
            : isLargeTablet
            ? "1rem"
            : isLargeScreen
            ? "1rem"
            : 0,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
      />

      <GenericAutocomplete
        dataList={
          chartHeaderObject.selectedChartGroupData
            ? chartHeaderObject.selectedChartGroupData.map((subGroup) => ({
                label: subGroup.chartSubGroup,
              }))
            : []
        }
        changeHandler={handleSelectChartSubGroup}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        value={chartHeaderObject.selectedChartSubGroup}
        label={t("CHARTCATSUB")}
        popperStyle={
          {
            // placement: `${drawerWidth === 0 ? "left" : "bottom"}`,
            // width: `${drawerWidth === 0 ? "300px" : "auto"}`,
          }
        }
        style={{
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
      />
    </Paper>
  );
};

export default ChartHeader;
