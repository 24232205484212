import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Link,
  Grid,
  Stack,
  Paper,
  InputAdornment,
  FormControl,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import { useTheme } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TiUserAdd } from "react-icons/ti";
import LanguageSelector from "./LanguageSelector";
import { useFormSize } from "../../size";
import { PAPER_ELEVATION, BORDER_RADIUS } from "../../constants";

function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    password2: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme(); // Theme
  const [registrationMessage, setRegistrationMessage] = useState("");
  const [registrationError, setRegistrationError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Client-Side Validation
  const validateForm = () => {
    if (!formData.email || !formData.password || !formData.password2) {
      setRegistrationError(t("All Fields Required"));
      clearMessages();
      return false;
    }
    if (formData.password !== formData.password2) {
      setRegistrationError(t("Passwords Must Match"));
      clearMessages();
      return false;
    }
    return true;
  };

  const handleRegister = async () => {
    if (!validateForm()) return; // Stop if validation fails

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/accounts/register/`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setRegistrationMessage(t("registrationSuccess"));
        setRegistrationError("");
        clearMessages();
        setTimeout(() => {
          navigate("/login"); // Redirect after 3 seconds
        }, 3000);
      } else {
        setRegistrationMessage("");
        if (data.errors) {
          // Map backend error messages to translations
          const errorMessages = Object.values(data.errors)
            .flat()
            .map((msg) => t(msg)) // Translate backend error keys
            .join(" ");
          setRegistrationError(errorMessages);
        } else {
          setRegistrationError(t("registrationError"));
        }
        clearMessages();
      }
    } catch (error) {
      setRegistrationMessage("");
      setRegistrationError(t("Registration Error"));
      clearMessages();
    }
  };

  const clearMessages = () => {
    // Clear both success and error messages after 3 seconds
    setTimeout(() => {
      setRegistrationMessage("");
      setRegistrationError("");
    }, 3000);
  };

  const panelWidth = useFormSize().panelWidth;
  const inputPropStyle = {
    color: theme.palette.getContrastText(theme.palette.background.default),
    background: theme.palette.background.default,
    borderRadius: `${BORDER_RADIUS}px`,
    fontSize: useFormSize().fontSizeField,
  };
  const textFieldStyle = {
    fontSize: useFormSize().fontSizeInput,
    letterSpacing: useFormSize().letterSpacingInput,
    borderRadius: `${BORDER_RADIUS}px`,
    background: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.default),
  };
  const inputAdormentStart = (icon) => (
    <InputAdornment position="start">
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Divider
          orientation="vertical"
          variant="fullWidth"
          flexItem
          style={{
            borderColor: theme.palette.primary.dark,
            borderWidth: "1px",
            marginBottom: "2px",
            marginTop: "2px",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        />
      </div>
    </InputAdornment>
  );
  const helperProps = {
    sx: {
      ...textFieldStyle,
    },
    style: { marginTop: "0px" },
  };

  return (
    <div
      style={{
        overflow: "overlay",
        height: "100vh",
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            height: {
              xs: "auto",
              sm: "auto",
              md: "100vh",
              lg: "100vh",
              xl: "100vh",
            },
            paddingBottom: "2rem",
            width: "100%",
          }}
          style={{ margin: 0 }}
        >
          <Grid item xs={10} sm={10} lg={6} style={{ paddingLeft: 0 }}>
            <Stack
              maxWidth="lg"
              spacing={{
                xs: 0,
                sm: -4,
                md: -4,
                lg: -5,
                xl: -6,
              }}
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center" }}
            >
              <Box
                component="img"
                src={`https://oxoia-platform.s3.eu-central-2.amazonaws.com/Oxoia-02.png`}
                alt="Oxoia Logo"
                sx={{ width: "100%", display: "flex" }}
              />
              <Typography
                align="right"
                variant="h1"
                sx={{
                  textTransform: "uppercase",
                  color: theme.palette.primary.contrastText,
                  fontSize: useFormSize().fontSize,
                  fontWeight: 900,
                  letterSpacing: ".2rem",
                  visibility: useFormSize().visibilityDecoration,
                  height: useFormSize().heightDecoration,
                }}
              >
                {t("createYourAccount")}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            style={{ paddingTop: "0px", paddingLeft: 0 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={2}
              sx={{
                width: panelWidth,
                marginBottom: "5px",
              }}
            >
              <LanguageSelector integration="noAuth" />
            </Box>
            <Paper
              className="login"
              elevation={PAPER_ELEVATION}
              sx={{
                width: panelWidth,
                marginBottom: "1rem",
              }}
            >
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <TiUserAdd
                  style={{
                    height: "100px",
                    width: "100px",
                    color: theme.palette.primary.dark,
                    marginTop: "1rem",
                    marginBottom: "0.25rem",
                  }}
                />
                <FormControl
                  sx={{
                    width: "80%",
                    "& .MuiInputBase-input": {
                      padding: useFormSize().paddingForm,
                    },
                  }}
                >
                  <form>
                    <TextField
                      fullWidth
                      id="input-email-textfield"
                      variant="outlined"
                      margin="dense"
                      name="email"
                      autoComplete="email"
                      style={{ marginTop: "0px" }}
                      sx={textFieldStyle}
                      helperText={t("email")}
                      FormHelperTextProps={helperProps}
                      value={formData.email}
                      onChange={handleChange}
                      InputProps={{
                        style: inputPropStyle,
                        startAdornment: inputAdormentStart(
                          <EmailIcon
                            fontSize={useFormSize().inputIconSize}
                            sx={{
                              color: theme.palette.primary.dark,
                              paddingRight: "2px",
                            }}
                          />
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      id="input-password-textfield"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      margin="dense"
                      name="password"
                      autoComplete="new-password"
                      style={{ marginTop: "0px" }}
                      sx={textFieldStyle}
                      helperText={t("pass")}
                      FormHelperTextProps={helperProps}
                      value={formData.password}
                      onChange={handleChange}
                      InputProps={{
                        style: inputPropStyle,
                        startAdornment: inputAdormentStart(
                          <LockIcon
                            fontSize={useFormSize().inputIconSize}
                            sx={{
                              color: theme.palette.primary.dark,
                              paddingRight: "2px",
                            }}
                          />
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              className="loginScreen"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      id="input-password2-textfield"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      margin="dense"
                      name="password2"
                      autoComplete="new-password"
                      style={{ marginTop: "0px" }}
                      sx={textFieldStyle}
                      helperText={t("passConfirm")}
                      FormHelperTextProps={helperProps}
                      value={formData.password2}
                      onChange={handleChange}
                      InputProps={{
                        style: inputPropStyle,
                        startAdornment: inputAdormentStart(
                          <LockIcon
                            fontSize={useFormSize().inputIconSize}
                            sx={{
                              color: theme.palette.primary.dark,
                              paddingRight: "2px",
                            }}
                          />
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              className="loginScreen"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </FormControl>

                {/* Success and Error messages */}
                {registrationMessage && (
                  <Typography variant="h6" color="secondary" gutterBottom>
                    {registrationMessage}
                  </Typography>
                )}
                {registrationError && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{
                      wordWrap: "break-word",
                      fontSize: "0.875rem",
                      lineHeight: 1.5,
                    }}
                  >
                    {registrationError}
                  </Typography>
                )}
              </Stack>

              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  paddingTop: "1rem",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    maxWidth: "50%",
                  }}
                  onClick={handleRegister}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className="login"
                    sx={{
                      fontWeight: "900",
                    }}
                  >
                    {t("Register")}
                  </Typography>
                </Button>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                  sx={{
                    maxWidth: "500px",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      alignItems: "right",
                      paddingRight: "5px",
                      color: theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                    }}
                  >
                    {t("navigateToLogin")}
                  </Typography>
                  <Link
                    to="/register"
                    onClick={() => navigate("/login")}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        fontSize: "1rem",
                        alignItems: "right",
                        color: theme.palette.secondary.dark,
                      }}
                    >
                      {t("toLogin")}
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Register;
